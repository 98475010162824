export const taskConstants = {
    STOPPED: 1,
    STARTED: 2,
    LOST: 3,
    WON: 4,
    CRASHED: 5,
    STOPPING: 6,
    PAUSE: 7,
    ENDED: 8,
    REMOVING: 9
};