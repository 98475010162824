import React, {useEffect, useState} from "react";
import axios from "axios";
import {useHistory} from "react-router";
import {tenderStatus} from "./SearchWidget";
import BootstrapTable from "react-bootstrap-table-next";
import {apiConstants} from "../../constants/ApiConstants";
import TimeToEnd from "./TimeToEnd";
import {getTenderLink} from "./";

export default function TenderDetails({id}) {

  let history = useHistory();
  const [tender, setTender] = useState();

  useEffect(() => {
    axios.get(`${apiConstants.SEARCH_URL}/tenders/${id}`).then((resp) => {
      const data = resp.data;

      document.body.style.overflowY = 'hidden';
      setTender(data);
    });
  }, []);

  if (tender) {

    const columns = [{
      dataField: 'id',
      text: 'Название',
      formatter: function (cell, row) {
        return row.name
      }
    }, {
      dataField: 'quantity',
      text: 'Кол-во'
    }, {
      dataField: 'price',
      text: 'Цена за ед',
      formatter: (cell, row) => {
        return new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(row.price)
      }
    }];

    return (
      <>
        <div className="bs-canvas-backdrop show"/>
        <div className="bs-canvas bs-canvas-anim bs-canvas-right position-fixed bg-white h-100">
          <header className="bs-canvas-header bg-secondary pt-3 pb-3 pr-3 pl-5 overflow-auto position-relative">
            <button
              type="button"
              className="bs-canvas-close float-left close position-absolute"
              style={{left: "20px"}}
              onClick={() => {
                document.body.style.overflowY = 'scroll';
                history.push('/search');
              }}
            >
              <span aria-hidden="true" className="text-light">&times;</span>
            </button>
            <h4 className="d-inline-block text-light mb-0 float-left tender-title">
              <a href={getTenderLink(tender)} target={"_blank"} className={'text-light'}>{tender.name}</a>
            </h4>
          </header>
          <div className="bs-canvas-content px-2 py-3">

            <div className={"col-12"}>
              <Platform code={tender.platform.code} />
            </div>
            <div className={"col-12 mb-4"}>
              <span className={"h5"}>
                <span className="badge badge-dark">{tender.law.name}</span>
                <span className="badge badge-info ml-2">{tender.type.name}</span>
                {tenderStatus(tender.status.code)}
              </span>
            </div>

            <div className={"col-12 mb-4"}>
              <div className={"mb-2"}>
                Дата публикации: {new Date(tender.date_published).toLocaleString("ru-RU")}
              </div>
              <div className={"mb-2"}>
                Дата окончания: {new Date(tender.date_end).toLocaleString("ru-RU")}&nbsp;&nbsp;
                <b>
                  <TimeToEnd date={tender.date_end} />
                </b>
              </div>
            </div>

            <div className={'col-12 mb-4'}>
              <h6>Регион</h6>
              {tender.region.federal_district} фед. округ / {tender.region.name}
            </div>

            <div className={'col-12 mb-4'}>
              <h6>Заказчик</h6>
              {tender.customer.name}
            </div>

            <div className={'col-12 mb-4'}>
              <h6>Стартовая цена</h6>
              <h3>{new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(tender.start_price)}</h3>
            </div>

            <div className={'col-12 mb-4'}>
              <h6>Позиции</h6>
              <BootstrapTable keyField='id' data={tender.items} columns={columns} classes={"table table-sm table-striped table-row-border table-condensed"}/>
            </div>

            <div className={'col-12 mb-4 flex-row'}>
              <h6 className={'mb-4'}>Документы</h6>
              {
                tender.files.map((file, index) => {
                  return (
                    <div key={index} className={"mb-2"}>
                      <FileType filename={file.name} />&nbsp;
                      <FileLink file={file} platform={tender.platform.code} />
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return <></>
  }
}

const FileLink = ({file, platform}) => {
  if (
    (platform === 'eat' || platform === 'mosru' || platform === 'mosreg') &&
    (file.name.includes(".doc") || file.name.includes(".xls") || file.name.includes(".pdf"))
  ) {
    return (
      <a href={`https://docs.google.com/viewer?url=${file.link}`} className={"text-dark"} target={"_blank"}>{file.name}</a>
    )
  } else {
    return (
      <a href={file.link} className={"text-dark"} target={"_blank"}>{file.name}</a>
    )
  }
}

const FileType = ({filename}) => {
  const filetype = filename.split(".").pop();
  switch (filetype) {
    case "doc":
    case "docx":
      return <i className="fa fa-file-word-o text-primary" aria-hidden="true"/>
    case "xls":
    case "xlsx":
      return <i className="fa fa-file-excel-o text-success" aria-hidden="true"/>
    case "jpg":
    case "png":
    case "gif":
      return <i className="fa fa-file-image-o" aria-hidden="true"/>
    case "pdf":
      return <i className="fa fa-file-pdf-o text-danger" aria-hidden="true"/>
    case "zip":
    case "rar":
    case "7z":
      return <i className="fa fa-file-archive-o" aria-hidden="true"/>
    default:
      return <i className="fa fa-file" aria-hidden="true"/>
  }
}

const Platform = ({code}) => {
  switch (code) {
    case "rts-market":
      return (
        <>
          <img src={"/images/logo/rts.svg"} width={"150px"} className={"pb-2"} alt="РТС-маркет" />
        </>
      );
    case "eat":
      return (
        <>
          <img src={"/images/logo/eat.png"} width={"150px"} className={"pb-2"} alt="Березка" />
        </>
      );
    case "mosreg":
      return (
        <>
          <img src={"/images/logo/mosreg.jpg"} width={"150px"} className={"pb-2"} alt="Электронный магазин Московской области" />
        </>
      );
    case "mosru":
      return (
        <>
          <img src={"/images/logo/mosru.png"} width={"150px"} className={"pb-2"} alt="Портал поставщиков Москвы" />
        </>
      );
    case "gz-spb":
      return (
        <>
          <img src={"/images/logo/gz-spb.png"} width={"150px"} className={"pb-2"} alt="АИС ГЗ" />
        </>
      );
    default:
      return (
        <>
        </>
      );
  }
}
