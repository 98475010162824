import React from 'react';

export default function Platform({code}) {
  switch (code) {
    case "rts-market":
      return (
        <>
          <img src={"/images/icons/rts-tender.ru.jpg"} className="tender-platform" alt="РТС-маркет" /><div className="pl-2 font-weight-bold">РТС-маркет</div>
        </>
      );
    case "eat":
      return (
        <>
          <img src={"/images/icons/agregatoreat.ru.png"} className="tender-platform" alt="Березка" /><div className="pl-2 font-weight-bold">Березка</div>
        </>
      );
    case "mosreg":
      return (
        <>
          <img src={"/images/icons/mosreg.ru.png"} className="tender-platform" alt="Электронный магазин Московской области" /><div className="pl-2 font-weight-bold">Электронный магазин МО</div>
        </>
      );
    case "mosru":
      return (
        <>
          <img src={"/images/icons/mos.ru.png"} className="tender-platform" alt="Портал поставщиков Москвы" /><div className="pl-2 font-weight-bold">Портал поставщиков Москвы</div>
        </>
      );
    case "gz-spb":
      return (
        <>
          <img src={"/images/icons/gz-spb.png"} className="tender-platform" alt="АИС ГЗ" /><div className="pl-2 font-weight-bold">АИС ГЗ</div>
        </>
      );
    default:
      return (
        <>
          <div className="pl-2 font-weight-bold">Новая площадка</div>
        </>
      );
  }
}