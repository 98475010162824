import React from "react"
import {ContentLayout} from "../layouts/ContentLayout";
import B24SettingFormContainer from "../components/containers/B24SettingsFormContainer";

export function B24() {
    return (
        <ContentLayout title="Битрикс24 интеграция">
            <div className="row">
                <B24SettingFormContainer />
            </div>
        </ContentLayout>
    );
}