import React from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import {useSelector} from "react-redux";
import {acccessConstants} from "../../constants/AccessConstants";
import {Forbidden} from "../Forbidden";
import {useParams} from "react-router";
import MosSettingsFormContainer from "../../components/containers/MosSettingsFormContainer";

export let MosSettingsForm = () => {
  const user = useSelector(state => state.userReducer)

  let {id} = useParams();

  return (
    <ContentLayout title={id ? "Редактирование аккаунта Портала поставщиков" : "Добавить аккаунт для Портала поставщиков"}>
      <div className="row">
        {
          user.role.toUpperCase() === acccessConstants.ADMIN ?
            <div className="col-6">
              <br/>
              <MosSettingsFormContainer id={id}/>
            </div> :
            <div className="col-12">
              <Forbidden/>
            </div>
        }
      </div>
    </ContentLayout>
  );
};