import React from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import {useParams} from "react-router";
import UserFormContainer from "../../components/containers/UserFormContainer";

export function UserForm() {

    let {id} = useParams();

    return (
        <ContentLayout title={id ? "Редактирование пользователя" : "Добавление пользователя"}>
            <div className="row">
                <div className="col-6 p-0">
                    <br />
                    <UserFormContainer id={id} />
                </div>
            </div>
        </ContentLayout>
    );
}