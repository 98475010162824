import React from "react"

export function EditProfileForm(props) {
    return (
        <form name="form" onSubmit={props.onSubmit}>
            {props.result.type === 'error' && <div className="alert alert-danger">{props.result.message}</div>}
            {props.result.type === 'success' && <div className="alert alert-success">{props.result.message}</div>}
            <div className="form-group">
                <label htmlFor="login">Логин: </label>
                <input name="login" type="text" className="form-control" id="login" onChange={props.onChange} value={props.data.login} disabled="disabled" />
            </div>
            <div className="form-group">
                <label htmlFor="password">Новый пароль</label>
                <input name="password" type="password" className="form-control" id="password" onChange={props.onChange} value={props.data.password} placeholder="Пароль" />
            </div>
            <div className="form-group">
                <label htmlFor="password">Повторите пароль</label>
                <input name="repassword" type="password" className="form-control" id="repassword" onChange={props.onChange} value={props.data.repassword} placeholder="Повторите пароль" />
            </div>
            <button type="submit" className="btn btn-primary">Сохранить</button>
        </form>
    );
}