import {formConstants} from "../constants/FormConstants";

export const formActions = {
    loading,
    error,
    reset
};

function loading() {
    return {type: formConstants.FORM_LOADING}
}

function error(message) {
    return {type: formConstants.FORM_ERROR, message: message}
}

function reset() {
    return {type: formConstants.FORM_RESET}
}