import {formConstants} from "../constants/FormConstants";
import {settingsService} from "../services/SettingsService";
import {taskServices} from "../services/TaskServices";
import {history} from "../app/History";
import {routeConstants} from "../constants/RouteConstants";

export const settingsActions = {
    add,
    update
};

function add(params, prefix = '') {
    return dispatch => {
        settingsService.setPrefix(prefix).add(params)
          .then(response => taskHandler(response, dispatch, prefix))
          .catch((e) => {
              dispatch({type: formConstants.FORM_ERROR, message: e.response.data})
          });
    }
}

function update(id, params, prefix = '') {
    return dispatch => {
        settingsService.setPrefix(prefix).update(id, params)
            .then(response => taskHandler(response, dispatch, prefix))
            .catch(() => {
                dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
            });
    }
}

let taskHandler = (response, dispatch, prefix) => {
    if (response.status === 200) {
        const result = response.data;
        if (result.status === "error") {
            dispatch({type: formConstants.FORM_ERROR, message: result.message})
        } else {
            dispatch({type: formConstants.FORM_SENT, message: "Successful"});
            if (prefix === 'mo') {
                history.push(routeConstants.MOSREG_SETTINGS);
            } else if (prefix === 'eat') {
                history.push(routeConstants.EAT_SETTINGS);
            } else if (prefix === 'app') {
                history.push(routeConstants.APP_SETTINGS);
            } else if (prefix === 'b24') {
                history.push(routeConstants.B24_INTEGRATION);
            } else {
                history.push(routeConstants.MOS_SETTINGS);
            }
        }
    } else {
        dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
    }
};