import React, {useState} from 'react'
import {useSelector} from "react-redux";
import dateFormat from "dateformat";
export default function BillInfo() {

  const user = useSelector(state => state.userReducer);

  if (user?.expire) {
    const compareDate = new Date(user.expire);
    const today = new Date();

    let timeDiff = compareDate.getTime() - today.getTime();
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays < 3) {
      return (
        <div className={'bill-warning'}>Внимание! Робот оплачен до <b>{dateFormat(compareDate, "dd.mm.yy")}</b>, во избежание перерыва в оказании услуг, оплатите счет направленный на вашу почту.</div>
      )
    }
  } else {
    return (
      <></>
    )
  }
}
