import React from "react";
import {formActions} from "../../actions/FormActions";
import {settingsService} from "../../services/SettingsService";
import {connect} from "react-redux";
import Axios from "axios";
import {apiConstants} from "../../constants/ApiConstants";
import {settingsActions} from "../../actions/SettingsActions";

class B24SettingFormContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state ={
      b24url: '',
      connected: false,
      user: 0,
      direction: '',
      status: '',

      newConnection: false,

      connection: {
        b24url: '',
        user: '',
        direction: '',
        status: ''
      },

      users: [],
      directions: [],
      statuses: []
    }

    this.prefix = 'b24';

    this.config = {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(formActions.reset());

    settingsService.setPrefix(this.prefix).get("",(data) => {
      this.setState({
        newConnection: data.url.length < 1,
        connection: {
          b24url: data.url,
          user: data.user,
          direction: data.direction,
          status: data.status,
        }
      })
    });
  }

  onNewConnection = () => {
    this.setState({
      newConnection: true
    });
  }

  onSaveB24Link = () => {
    const params = new URLSearchParams();
    params.append('b24url', this.state.b24url);

    Axios.post(apiConstants.API_URL + '/b24params', params, this.config)
      .catch()
      .then(response => {
        if (response.status === 200) {
          const result = response.data;
          if (result.status === 'success') {
            const data = result.data;
            this.setState({
              users: data.users,
              directions: data.directions,
              connected: true
            })
          }
        }
      });
  }

  onChangeDirection = (e) => {
    this.setState({
      direction: e.target.value,
    })

    const params = new URLSearchParams();
    params.append('b24url', this.state.b24url);
    params.append('b24direction', e.target.value);

    Axios.post(apiConstants.API_URL + '/b24statuses', params, this.config)
      .catch()
      .then(response => {
        if (response.status === 200) {
          const result = response.data;
          if (result.status === 'success') {
            const data = result.data;
            this.setState({
              statuses: data,
            })
          }
        }
      });
  }

  onChangeUser = (e) => {
    this.setState({
      user: e.target.value,
    })
  }

  onChangeStatus = (e) => {
    this.setState({
      status: e.target.value,
    })
  }

  onSaveConnection = (e) => {
    e.preventDefault();

    const { dispatch } = this.props;
    dispatch(formActions.loading());

    const params = new URLSearchParams();
    params.append('b24url', this.state.b24url);
    params.append('b24user', this.state.user);
    params.append('b24direction', this.state.direction);
    params.append('b24status', this.state.status);

    dispatch(settingsActions.update("", params, this.prefix));

    this.setState({
      newConnection: false
    })
  }

  render() {
    if (this.state.newConnection) {
      return (
        <div className="col-6">
          <br/>
          <div className="form-group">
            <label htmlFor="login">Ссылка на вебхук Битрикс24: </label>
            <input
              name="login"
              type="text"
              className="form-control"
              id="url"
              value={this.state.b24url}
              onChange={(e) => {
                this.setState({b24url: e.target.value})
              }}
              readOnly={this.state.connected}
            />
          </div>
          {
            !this.state.connected &&
            <button className="btn btn-primary"
                    onClick={(e) => this.onSaveB24Link(e)}> Подключить</button>
          }
          {
            Object.keys(this.state.users).length > 0 &&
            <div className="form-group">
              <label htmlFor="login">Пользователь: </label>
              <select
                name="b24гыукы"
                value={this.state.user}
                onChange={(e) => this.onChangeUser(e)}
                className="form-control"
              >
                <option id="null" value="">-- Выберите пользователя --</option>
                {
                  Object.keys(this.state.users).map((key) => {
                    return (<option id={key} value={key}>{this.state.users[key]}</option>)
                  })
                }
              </select>
              <small className="form-text text-muted">Выберите ответственного пользователя в Битрикс24 за сделки</small>
            </div>
          }
          {
            Object.keys(this.state.directions).length > 0 &&
            <div className="form-group">
              <label htmlFor="login">Направление для сделки: </label>
              <select
                name="b24directions"
                value={this.state.direction}
                onChange={(e) => this.onChangeDirection(e)}
                className="form-control"
              >
                <option id="null" value="">-- Выберите направление --</option>
                {
                  Object.keys(this.state.directions).map((key) => {
                    return (<option id={key} value={key}>{this.state.directions[key]}</option>)
                  })
                }
              </select>
              <small className="form-text text-muted">Выйгранные закупки при выгрузке контракта будут создаваться как
                сделки в выбраном направлении</small>
            </div>
          }
          {
            Object.keys(this.state.statuses).length > 0 &&
            <>
              <div className="form-group">
                <label htmlFor="login">Стадия сделки: </label>
                <select
                  name="b24directions"
                  value={this.state.status}
                  onChange={(e) => this.onChangeStatus(e)}
                  className="form-control"
                >
                  <option id="null" value="">-- Выберите стадию --</option>
                  {
                    Object.keys(this.state.statuses).map((key) => {
                      return (<option id={key} value={key}>{this.state.statuses[key]}</option>)
                    })
                  }
                </select>
                <small className="form-text text-muted">Выберите стадию в которой будут вновь созданные сделки</small>
              </div>
              <button className="btn btn-primary" onClick={this.onSaveConnection} disabled={this.props.result.type === 'loading'}>
                Сохранить
              </button>
            </>
          }
        </div>
      );
    } else {
      return (
        <div className="col-6">
          <br/>
          <div className="form-group">
            <label htmlFor="login">Подключен Битрикс24: </label>
            <input
              name="url"
              type="text"
              className="form-control"
              id="url"
              value={this.state.connection.b24url}
              disabled="true"
            />
          </div>
          <button className="btn btn-primary" onClick={this.onNewConnection} disabled={this.props.result.type === 'loading'}>
            Изменить
          </button>
        </div>
      )
    }
  }
}

function mapStateToProps(store) {
  return {
    user: store.userReducer,
    result: store.formReducer
  };
}

export default connect(mapStateToProps)(B24SettingFormContainer);