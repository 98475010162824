import React from "react"
import {taskConstants} from "../constants/TaskConstants";
import {taskServices} from "../services/TaskServices";
import {history} from "../app/History";
import {routeConstants} from "../constants/RouteConstants";

export class ActionButtons extends React.Component {

    constructor(props) {
        super(props);

        this.prefix = '';
        this.id = props.id;

        this.state = {
            status: props.status
        };

        if (props.prefix !== undefined) {
            this.prefix = props.prefix;
        }

        this.handleRun = this.handleRun.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.status !== this.props.status) {
            this.setState({
                status: this.props.status
            });
        }
    }

    handleRun() {
        taskServices.setPrefix(this.prefix).run(this.id).then(respone => {
            if (respone.status === 200) {
                this.setState({
                    status: taskConstants.STARTED
                })
            }
        });
    }

    handleStop() {
        taskServices.setPrefix(this.prefix).stop(this.id).then(respone => {
            if (respone.status === 200) {
                this.setState({
                    status: taskConstants.STOPPING
                })
            }
        });
    }

    handleEdit() {
        if (this.prefix === 'mo') {
            history.push(routeConstants.MOSREG_TASKS + '/' + this.id);
        } else if (this.prefix === 'eat') {
            history.push(routeConstants.EAT_TASKS + '/' + this.id);
        } else {
            history.push(routeConstants.MOS_TASKS + '/' + this.id);
        }
    }

    handleDelete() {
        taskServices.setPrefix(this.prefix).remove(this.id).then(respone => {
            if (respone.status === 200) {
                this.setState({
                    status: taskConstants.REMOVING
                })
            }
        });
    }

    render() {

        this.showStartButton = true;
        this.showStopButton = true;
        this.showEditButton = true;
        this.showDeleteButton = true;

        switch (this.state.status) {
            case taskConstants.ENDED:
            case taskConstants.STARTED:
                this.showStartButton = false;
                this.showEditButton = false;
                this.showDeleteButton = false;
                break;
            case taskConstants.LOST:
            case taskConstants.STOPPING:
            case taskConstants.WON:
            case taskConstants.REMOVING:
                this.showStartButton = false;
                this.showStopButton = false;
                this.showEditButton = false;
                this.showDeleteButton = false;
                break;
            case taskConstants.CRASHED:
            case taskConstants.STOPPED:
                this.showStopButton = false;
                break;
        }

        return (
            <div className="btn-group btn-group-sm action-group" role="group">
                <button onClick={this.handleRun} disabled={!this.showStartButton && 'disabled'} type="button" className="btn btn-primary start" title="Запустить">
                    <i className="fa fa-play"></i>
                </button>
                <button onClick={this.handleStop} disabled={!this.showStopButton && 'disabled'} type="button" className="btn btn-secondary stop" title="Остановить">
                    <i className="fa fa-stop"></i>
                </button>
                <button onClick={this.handleEdit} disabled={!this.showEditButton && 'disabled'} type="button" className="btn btn-success edit" title="Редактировать">
                    <i className="fa fa-edit"></i>
                </button>
                <button onClick={this.handleDelete} disabled={!this.showDeleteButton && 'disabled'} type="button" className="btn btn-danger delete" title="Удалить">
                    <i className="fa fa-trash"></i>
                </button>
            </div>
        )
    }
}