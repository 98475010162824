import React from 'react';
import {Provider} from 'react-redux';
import AppRouter from './app/Router'
import {Store} from './app/Store'
import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {

    return (
        <Provider store={Store}>
            <AppRouter/>
        </Provider>
    );
}

export default App;
