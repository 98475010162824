import React from "react"

export function AddStopwordForm(props) {
    return (
        <form name="form" onSubmit={props.onSubmit}>
            {props.result.type === 'error' && <div className="alert alert-danger">{props.result.message}</div>}
            {props.result.type === 'success' && <div className="alert alert-success">{props.result.message}</div>}
            <div className="form-group col-12">
                <label htmlFor="stopword">Стоп слово</label>
                <input name="stopword" type="text" className="form-control" required id="stopword" placeholder="Слово, часть слова или фраза" onChange={props.onChange} value={props.data.login}/>
                <small id="stopwordHelp" className="form-text text-muted">Тендеры содержащие указанное слово не будут добавленны в базу, даже если содержат ключевые слова.</small>
            </div>

            <div className="col-12">
                <button type="submit" className="btn btn-primary" disabled={props.result.type === 'loading'}>
                    {props.result.type === 'loading' && <span className="spinner-border spinner-border-sm" role="status"></span>} Сохранить
                </button>
            </div>
        </form>
    );
}