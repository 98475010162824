import React from 'react';
import kladr from "./kladr";
import {Link} from "react-router-dom";
import {declOfNum, tenderStatus, tenderType} from "./SearchWidget";
import TimeToEnd from "./TimeToEnd";
import Platform from "./Platform";
import {useHistory} from "react-router";
import {getTenderLink} from "./";

export default function TenderItemList({row, onHideClick, onFavoriteClick, onDelete}) {

  const history = new useHistory();

  return (
    <div className="card mt-3">
      <div className="card-header d-flex">
        <div className="d-flex w-50">
          <div className="d-flex align-items-center">
            <Platform code={row.platform} />
          </div>
        </div>
        <div className="w-50 text-right">
          {
            /*
              <i className="fa fa-file-pdf-o fa-lg pl-3 text-danger"/>
              <i className="fa fa-print fa-lg pl-3 text-secondary"/>
            */
            <>
              {
                row.in_use ?
                  <span className="badge badge-success ml-2">Участвуем</span> :
                    (row.platform === 'mosru' || row.platform === 'mosreg' || row.platform === 'eat') ?
                      <button
                        type="button"
                        className="btn-sm"
                        style={{border: 'none', color: "#28a745"}}
                        title="Запустить"
                        onClick={() => {
                          history.push(`/search/${row.external_id}/task/${row.platform}`);
                        }}
                      >
                        <i className="fa fa-play"/>
                      </button> : ''
              }
              {
                onDelete &&
                  <span style={{cursor: "pointer"}} onClick={() => {onDelete(row.tender_id)}}>
                    <i className="fa fa-trash fa-lg pl-3 text-secondary" aria-hidden="true"/>
                  </span>
              }
              {
                onFavoriteClick ?
                  row.in_favorites ?
                    <span style={{cursor: "pointer"}} onClick={() => {onFavoriteClick(row.tender_id, false)}}>
                      <i className="fa fa-star fa-lg pl-3 text-warning" aria-hidden="true"/>
                    </span> :
                      <span style={{cursor: "pointer"}} onClick={() => {onFavoriteClick(row.tender_id)}}>
                        <i className="fa fa-star-o fa-lg pl-3 text-warning" aria-hidden="true"/>
                      </span> : ''
              }
              {
                onHideClick &&
                  <span style={{cursor: "pointer"}} onClick={() => onHideClick(row.tender_id)}>
                    <i className="fa fa-eye-slash fa-lg pl-3 text-secondary" aria-hidden="true"/>
                  </span>
              }
            </>
          }
        </div>
      </div>
      <div className="card-body tender-background">
        <div className="tender row">
          <div className="tender-body-left col-12 col-md-9">
            <span className="font-weight-bold">Закупка от {new Date(row.date_published).toLocaleDateString("ru-RU")}</span>
            {
              row.law === '223fz' ?
                <span className="badge badge-dark ml-2">223 ФЗ</span> :
                  row.law === '44fz' ?
                    <span className="badge badge-dark ml-2">44 ФЗ</span> :
                      ''
            }
            <span className="badge badge-info ml-2">{tenderType(row.type)}</span>
            <span className="tender-status">{tenderStatus(row.status)}</span>
            <div className="tender-title">
              <div className={"pt-2"}>{row.region.federal_district} фед. округ / {kladr[row.region.kladr]}</div>
              <div className={"pt-2"} style={{fontSize: "12px"}}>
                {row.customer.name}
              </div>
              <div className={"d-flex flex-row align-items-center"}>
                <Link to={`/search/${row.tender_id}`} className="h5 pt-2">
                  {row.name}
                </Link>
                <a href={getTenderLink(row)} target="_blank" className={"pl-2"}><i className="fa fa-external-link-square"/></a>
              </div>
              <div className={"py-2"}>
                <strong>
                  <TimeToEnd date={row.date_end} />
                </strong>
              </div>
            </div>
          </div>
          <div className="tender-body-right col-12 col-md-3 pb-3">
            <div className="tender-price font-weight-bold h5">
              {new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(row.start_price)}
            </div>
            <div className="tender-items">
              {row.items.length} {declOfNum(row.items.length, ['позиция', 'позиции', 'позиций'])}
            </div>
          </div>
          <div className={"col-12"}>
            {
              row.highlight && row.highlight.items && !row.highlight.name &&
              <div className={"card mb-3 highlight"}>
                <div className="card-header py-2 px-4">
                  Найдено в позициях
                </div>
                <div className="card-body p-2">
                  <ul className="list-group list-group-flush">
                    {
                      row.highlight.items.map((item, key) => {
                        return (
                          <li className="list-group-item" key={key} dangerouslySetInnerHTML={{__html: item}}/>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            }
            {
              row.highlight && row.highlight['docs.content'] && !row.highlight.name &&
              <div className={"card highlight"}>
                <div className="card-header py-2 px-4">
                  Найдено в документации
                </div>
                <div className="card-body p-2">
                  <ul className="list-group list-group-flush">
                    {
                      row.highlight['docs.content'].map((item, key) => {
                        return (
                          <li className="list-group-item" key={key} dangerouslySetInnerHTML={{__html: item}}/>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
