import React from "react"

export function MosTaskFormView(props) {
  return (
    <form name="form" onSubmit={props.onSubmit}>
      {props.result.type === 'error' && <div className="alert alert-danger">{props.result.message}</div>}
      <div className="form-group col-12">
        <label htmlFor="settings_id">Аккаунт</label>
        <select value={props.data.settings_id} name="settings_id" className="form-control" onChange={props.onChange}>
          {
            props.data.accounts && Object.keys(props.data.accounts).length > 0 ?
              Object.keys(props.data.accounts).map((key) => {
                const account = props.data.accounts[key];
                return <option value={key}>{account}</option>
              }) :
                <option key="empty" value="">- Добавьте аккаунт Портала поставщиков -</option>
          }
        </select>
        <small id="nameHelp" className="form-text text-muted">Выберите аккаунт на zakupki.mos.ru</small>
      </div>
      <div className="form-group col-12">
        <label htmlFor="name">Название котировчной сессии</label>
        <input name="name" type="text" className="form-control" id="name" placeholder="Название"
               onChange={props.onChange} value={props.data.name}/>
        <small id="nameHelp" className="form-text text-muted">Не объязательное, если не указать будет подгружено с
          zakupki.mos.ru</small>
      </div>
      <div className="form-group col-12">
        <label htmlFor="link">Ссылка</label>
        <input name="url" type="text" className="form-control" required id="link" placeholder="Ссылка"
               onChange={props.onChange} value={props.data.url}/>
        <small id="linkHelp" className="form-text text-muted">Ссылка на страницу котировочной сессии на портале
          zakupki.mos.ru</small>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="form-group col-4">
            <label htmlFor="firstprice">Закупка</label>
            <input name="buyPrice" type="text" required className="form-control" id="firstprice" placeholder="Цена"
                   onChange={props.onChange} value={props.data.buyPrice}/>
            <small id="firstpriceHelp" className="form-text text-muted">Укажите цену закупки</small>
          </div>
          <div className="form-group col-4">
            <label htmlFor="secondprice">Стоп-цена</label>
            <input name="stopPrice" type="text" required className="form-control" id="secondprice" placeholder="Цена"
                   onChange={props.onChange} value={props.data.stopPrice}/>
            <small id="secondpriceHelp" className="form-text text-muted">Укажите минимальную цену ниже которой нельзя
              делать ставки</small>
          </div>
          <div className="form-group col-4">
            <label htmlFor="time">Время</label>
            <input name="startBefore" type="text" required className="form-control" id="time"
                   placeholder="Время (минут)" onChange={props.onChange} value={props.data.startBefore}/>
            <small id="timeHelp" className="form-text text-muted">Укажите за сколько минут начинать делать
              ставки</small>
          </div>
        </div>
      </div>
      <div className="col-12">
        <button type="submit" className="btn btn-primary" disabled={props.result.type === 'loading'}>
          {props.result.type === 'loading' &&
          <span className="spinner-border spinner-border-sm" role="status"></span>} Сохранить
        </button>
      </div>
    </form>
  );
}