/* eslint-disable no-unused-vars */
import React from "react";
import {PaginationTotalStandalone} from "react-bootstrap-table2-paginator";
import {Pagination} from "./Pagination";

export function PaginationToolbar(props) {
  const { isLoading, paginationProps } = props;
  const {
    sizePerPageList,
    sizePerPage,
    totalSize,
    onSizePerPageChange = [
      { text: "15", value: 15, page: 15 },
      { text: "30", value: 30, page: 30 },
      { text: "100", value: 100, page: 100 }
    ]
  } = paginationProps;
  const style = {
    width: "75px"
  };

  const onSizeChange = event => {
    const newSize = +event.target.value;
    onSizePerPageChange(newSize);
  };

  if (totalSize > sizePerPage) {
    return (
      <div className="d-flex align-items-center py-3">
        <select
          disabled={totalSize === 0}
          className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${totalSize ===
          0 && "disabled"}`}
          onChange={onSizeChange}
          value={sizePerPage}
          style={style}
        >
          {sizePerPageList.map(option => {
            const isSelect = sizePerPage === option.value;
            return (
              <option
                key={option.text}
                value={option.value}
                className={`btn ${isSelect ? "active" : ""}`}
              >
                {option.text}
              </option>
            );
          })}
        </select>
        <PaginationTotalStandalone
          className="text-muted"
          {...paginationProps}
          paginationTotalRenderer={(from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
            Показано с {from} по {to} из {size} записей
          </span>
          )}
        />
      </div>
    );
  } else {
    return '';
  }
}
