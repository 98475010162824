import React from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import {ParseResultListContainer} from "../../components/containers/ItemsListContainers";

export function ParseResult() {
    return (
        <ContentLayout title="Результаты парсинга">
            <ParseResultListContainer />
        </ContentLayout>
    );
}