import React from "react";
import Axios from "axios";
import {Pagination} from "../../widgets/Pagination";
import {Loading} from "../../widgets/Loading";

export function newItemsList(WrappedComponent, dataUrl, autoUpdate = true) {
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                isLoading: true,
                tasks: [],
                page: 1,
                pagination: []
            };
        }

        handlePageClick = (page) => {
            this.setState({
               page: page
            });
            this.loadData(page);
        };

        loadData = (page = 1) => {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': 'bearer ' + token
                }
            };

            Axios.get(dataUrl + "?page=" + page, config ).then((response) => {
                const result = response.data;
                const items = result.data.Items;
                const pagination = result.data.Pagination;
                this.setState(state => ({items: items, pagination: pagination, isLoading: false}));
            });
        };

        componentDidMount() {
            this.loadData();

            if (autoUpdate) {
                this.timerID = setInterval(
                    () => this.loadData(this.state.page),
                    2000
                );
            }
        }

        componentWillUnmount() {
            if (autoUpdate) {
                clearInterval(this.timerID);
            }
        }

        render() {
            if (this.state.isLoading) {
                return (
                    <Loading />
                );
            } else {
                return (
                    <React.Fragment>
                        <WrappedComponent items={this.state.items}/>
                        <br />
                        {this.state.pagination.Pages !== undefined && <Pagination pagination={this.state.pagination} handle={this.handlePageClick} />}
                    </React.Fragment>
                );
            }
        }
    }
}