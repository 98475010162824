import React from "react"
import {Loading} from "../../widgets/Loading";
import {connect} from "react-redux";
import {formActions} from "../../actions/FormActions";
import {taskActions} from "../../actions/TaskActions";
import {taskServices} from "../../services/TaskServices";
import {MosregTaskFormView} from "../views/MosregTaskFormView";
import Axios from "axios";
import {apiConstants} from "../../constants/ApiConstants";

class MosregTaskFormContainer extends React.Component {

    constructor(props) {
        super(props);

        let url = '';
        if (props.external_id !== undefined) {
            url = `https://market.mosreg.ru/Trade/ViewTrade/${props.external_id}`
        }

        this.state = {
            isLoading: !!props.id,
            settings_id: '',
            name: '',
            url: url,
            buyPrice: 0,
            startPrice: 0,
            stopPrice: 0,
            startBefore: 0,
            accounts: []
        };

        this.prefix = 'mo';
        this.id = props.id;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'Authorization': 'bearer ' + token
            }
        };

        if (this.id) {
            const {dispatch} = this.props;
            dispatch(formActions.reset());

            Axios.get(apiConstants.API_URL + "/mo/accounts", config).then((resp) => {
                const accounts = resp.data.data;

                taskServices.setPrefix(this.prefix).get(this.id, (data) => {
                    this.setState({
                        isLoading: false,
                        settings_id: data.SettingsID,
                        name: data.Name,
                        url: data.Link,
                        buyPrice: data.FirstPrice,
                        startPrice: data.ThirdPrice,
                        stopPrice: data.SecondPrice,
                        startBefore: data.Time,
                        accounts: accounts
                    })
                });
            })
        } else {
            Axios.get(apiConstants.API_URL + "/mo/accounts", config).then((resp) => {
                const {data} = resp.data;
                console.log(Object.keys(data));
                this.setState({
                    settings_id: Object.keys(data).length > 0 ? Object.keys(data)[0] : '',
                    accounts: data,
                });
            })
        }
    }

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        dispatch(formActions.loading());

        const params = new URLSearchParams();
        params.append('id', this.id);
        params.append('name', this.state.name);
        params.append('url', this.state.url);
        params.append('buyPrice', this.state.buyPrice);
        params.append('stopPrice', this.state.stopPrice);
        params.append('startPrice', this.state.startPrice);
        params.append('startBefore', this.state.startBefore);
        params.append('settings_id', this.state.settings_id);

        if (this.id) {
            dispatch(taskActions.updateTask(this.id, params, this.prefix));
        } else {
            dispatch(taskActions.addTask(params, this.prefix));
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        } else {
            return <MosregTaskFormView data={this.state} result={this.props.result} onChange={this.handleChange} onSubmit={this.handleSubmit}/>
        }
    }
}

function mapStateToProps(store) {
    return {
        result: store.formReducer
    };
}

export default connect(mapStateToProps)(MosregTaskFormContainer);