import React from "react"
import {taskConstants} from "../constants/TaskConstants";

export function TaskStatus(props) {

    let stateClass = "";
    let stateText = "";

    switch (props.status) {
        case taskConstants.STOPPED:
            stateClass = "badge-secondary";
            stateText = "Не работает";
            break;
        case taskConstants.STARTED:
            stateClass = "badge-primary";
            stateText = "Работает";
            break;
        case taskConstants.LOST:
            stateClass = "badge-warning";
            stateText = "Проиграли";
            break;
        case taskConstants.WON:
            stateClass = "badge-success";
            stateText = "Выиграли";
            break;
        case taskConstants.CRASHED:
            stateClass = "badge-danger";
            stateText = "Ошибка";
            break;
        case taskConstants.STOPPING:
            stateClass = "badge-secondary";
            stateText = "Остановка";
            break;
        case taskConstants.PAUSE:
            stateClass = "badge-secondary";
            stateText = "Пауза";
            break;
        case taskConstants.ENDED:
            stateClass = "badge-secondary";
            stateText = "Завершен";
            break;
    }

    return (
        <span className={"state-1 badge " + stateClass}>{stateText}</span>
    )
}

export function StateFilterOption({state, active, onClick}) {
    let stateClass = "";
    let stateText = "";

    switch (state.State) {
        case taskConstants.STOPPED:
            stateClass = "badge-secondary";
            stateText = "Не работает";
            break;
        case taskConstants.STARTED:
            stateClass = "badge-primary";
            stateText = "Работает";
            break;
        case taskConstants.LOST:
            stateClass = "badge-warning";
            stateText = "Проиграли";
            break;
        case taskConstants.WON:
            stateClass = "badge-success";
            stateText = "Выиграли";
            break;
        case taskConstants.CRASHED:
            stateClass = "badge-danger";
            stateText = "Ошибка";
            break;
        case taskConstants.STOPPING:
            stateClass = "badge-secondary";
            stateText = "Остановка";
            break;
        case taskConstants.PAUSE:
            stateClass = "badge-secondary";
            stateText = "Пауза";
            break;
        case taskConstants.ENDED:
            stateClass = "badge-secondary";
            stateText = "Завершен";
            break;
    }

    return (
      <button type="button" className={"btn btn-light"+(active ? ' active' : '')} onClick={() => onClick(state.State)}>
          {stateText} <span className={`badge ${stateClass}`}>{state.Count}</span>
      </button>
    )
}
