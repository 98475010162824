import React from "react"
import {QRCodeCanvas} from 'qrcode.react';

export function AppSettingsForm(props) {

    let emailPropClass =  props.data.sendEmail ? 'form-group' : 'form-group d-none';
    let telegramPropClass =  props.data.sendTelegram ? 'form-group' : 'form-group d-none';
    let whatsappPropClass = props.data.sendWhatsapp ? 'form-group' : 'form-group d-none';

    return (
        <div className={"col-6"}>
            <br />
            <form name="form" onSubmit={props.onSubmit}>
            {props.result.type === 'error' && <div className="alert alert-danger">{props.result.message}</div>}
            {props.result.type === 'success' && <div className="alert alert-success">{props.result.message}</div>}

            <div className="form-check">
                <input name="sendEmail" type="checkbox" className="form-check-input" id="sendEmail" onChange={props.onChange} checked={props.data.sendEmail} />
                <label className="form-check-label" htmlFor="sendEmail">Отправлять уведомления на Email</label>
            </div>
            <div className={emailPropClass}>
                <label htmlFor="email">Email</label>
                <input name="email" type="text" className="form-control" id="email" value={props.data.email} placeholder="example@example.com,example@example.com" onChange={props.onChange} />
                <small id="emailHelp" className="form-text text-muted">
                    Укажите email адреса для получения уведомлений через запятую без проблов
                </small>
            </div>
            <div className="form-check">
                <input name="sendTelegram" type="checkbox" className="form-check-input" id="sendtelegram" onChange={props.onChange} checked={props.data.sendTelegram} />
                <label className="form-check-label" htmlFor="sendtelegram">Отправлять уведомление в Telegram</label>
            </div>
            <div className={telegramPropClass}>
                <QRCodeCanvas value={`https://t.me/upravlator_bot?start=${props.data.telegramId}`}  bgColor={'#FFFFFF'} fgColor={"#000000"} size={256} level={'M'} includeMargin={false}/>
                <br />
                <small>Отсканируйте QR код или перейдите по <a href={`https://t.me/upravlator_bot?start=${props.data.telegramId}`}>ссылке</a>, для подключения к нашему боту</small>
            </div>

            <div className="form-check">
                <input name="sendWhatsapp" type="checkbox" className="form-check-input" id="sendwhatsapp" onChange={props.onChange} checked={props.data.sendWhatsapp} />
                <label className="form-check-label" htmlFor="sendwhatsapp">Отправлять уведомления на Whatsapp</label>
            </div>
            <div className={whatsappPropClass}>
                <label htmlFor="toWhatsapp">Получатель</label>
                <input name="toWhatsapp" type="text" className="form-control" id="toWazzup" value={props.data.toWhatsapp} placeholder="7xxxxxxxxxx" onChange={props.onChange} />
                <small id="toWhatsappHelp" className="form-text text-muted">
                    Укажите номер телефона получателя в международном формате 7xxxxxxxxxx
                </small>
            </div>


            <input name="domain" type="hidden" className="form-control" id="domain" value={props.data.domain} onChange={props.onChange} />
            <button type="submit" className="btn btn-primary" disabled={props.result.type === 'loading'}>
                {props.result.type === 'loading' && <span className="spinner-border spinner-border-sm"/>} Сохранить
            </button>

        </form>
        </div>
    );
}