import React from "react"
import {MosSettingsForm} from "../views/MosSettingsForm";
import {Loading} from "../../widgets/Loading";
import {connect} from "react-redux";
import {formActions} from "../../actions/FormActions";
import {settingsService} from "../../services/SettingsService";
import {settingsActions} from "../../actions/SettingsActions";
import {apiConstants} from "../../constants/ApiConstants";
import Axios from "axios";

class MosSettingsFormContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: !!props.id,
            name: '',
            login: '',
            password: '',
            startBefore: 0,
            tax: 'no',
            cert: '',
            certs: [],
        };
        this.id = props.id;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.id) {
            const {dispatch} = this.props;
            dispatch(formActions.reset());

            settingsService.setPrefix('').get(this.id, (data) => {
                this.setState({
                    isLoading: false,
                    name: data.Name,
                    login: data.Login,
                    password: data.Password,
                    startBefore: data.Time,
                    tax: data.Tax,
                    cert: data.UserCert,
                    certs: data.Certs,
                })
            });
        } else {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': 'bearer ' + token
                }
            };

            Axios.get(apiConstants.API_URL + '/cert', config).then((resp) => {
                const {data} = resp.data;
                this.setState({certs: data})
            });
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        dispatch(formActions.loading());

        const params = new URLSearchParams();
        params.append('name', this.state.name);
        params.append('login', this.state.login);
        params.append('password', this.state.password);
        params.append('startBefore', this.state.startBefore);
        params.append('tax', this.state.tax);
        params.append('cert', this.state.cert);

        if (this.id) {
            dispatch(settingsActions.update(this.id, params));
        } else {
            dispatch(settingsActions.add(params));
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        } else {
            return <MosSettingsForm data={this.state} result={this.props.result} onChange={this.handleChange} onSubmit={this.handleSubmit} />
        }
    }
}

function mapStateToProps(store) {
    return {
        result: store.formReducer
    };
}

export default connect(mapStateToProps)(MosSettingsFormContainer);