import React from "react"

export function ContentLayout(props) {
    return (
        <div className="row">
            <div className="col-12 pt-2">
                <h3>{props.title}</h3>
            </div>
            <div className="col-12">
                {props.children}
            </div>
        </div>
    );
}