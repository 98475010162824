import {userService} from '../services/UserServices';
import {formConstants} from "../constants/FormConstants";
import {userConstants} from "../constants/UserConstants";
import {history} from "../app/History";
import {routeConstants} from "../constants/RouteConstants";

export const userActions = {
    login,
    authorized,
    selfUpdate,
    add,
    update
};

function login(username, password) {
    return dispatch => {
        userService.login(username, password)
            .then(
                response => {
                    if (response.status === 200) {
                        const result = response.data;
                        if (result.status === "error") {
                            dispatch({type: formConstants.FORM_ERROR, message: result.message})
                        } else {
                            dispatch({type: formConstants.FORM_SENT, message: "Successful"});
                            localStorage.setItem("token", result.data);
                            window.location.reload();
                        }

                    } else {
                        dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
                    }
                }
            ).catch(
                () => {
                    dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
                }
            )
    };
}

function authorized(token) {
    return dispatch => {
        userService.checkAuthorization(token)
            .then(response => {
                const result = response.data;

                if (result.status === 'error') {
                    localStorage.removeItem('token');
                    window.location.reload();
                } else {
                    const user = result.data;
                    dispatch({type: userConstants.ADD_USER, user})
                }
            });
    }
}

function selfUpdate(params) {
    return dispatch => {
        userService.selfUpdate(params)
            .then(response => {
                if (response.status === 200) {
                    const result = response.data;
                    if (result.status === "error") {
                        dispatch({type: formConstants.FORM_ERROR, message: result.message})
                    } else {
                        dispatch({type: formConstants.FORM_SENT, message: "Настройки сохранены"});
                    }
                } else {
                    dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
                }
            })
            .catch(() => {
                dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
            });
    }
}

function add(params) {
    return dispatch => {
        userService.add(params)
            .then(response => {
                if (response.status === 200) {
                    const result = response.data;
                    if (result.status === "error") {
                        dispatch({type: formConstants.FORM_ERROR, message: result.message})
                    } else {
                        dispatch({type: formConstants.FORM_SENT, message: "Пользователь добавлен"});
                        history.push(routeConstants.USERS);
                    }
                } else {
                    dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
                }
            })
            .catch(() => {
                dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
            });
    }
}

function update(id, params) {
    return dispatch => {
        userService.update(id, params)
            .then(response => {
                if (response.status === 200) {
                    const result = response.data;
                    if (result.status === "error") {
                        dispatch({type: formConstants.FORM_ERROR, message: result.message})
                    } else {
                        dispatch({type: formConstants.FORM_SENT, message: "Изменения сохранены"});
                        history.push(routeConstants.USERS);
                    }
                } else {
                    dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
                }
            })
            .catch(() => {
                dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
            });
    }
}