import Axios from "axios";
import {apiConstants} from "../constants/ApiConstants";

export const userService = {
    token: localStorage.getItem('token'),
    login,
    logout,
    checkAuthorization,
    selfUpdate,
    remove,
    add,
    get,
    update
};

function login(login, password) {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    const params = new URLSearchParams();
    params.append('login', login);
    params.append('password', password);

    return Axios.post(apiConstants.API_URL + '/auth',params, config)
        .then((response) => {
            if (response.status === 200) {
                const result = response.data;
                if (result.status === "success") {
                    localStorage.setItem("token", result.data);
                }
            }

            return response;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
}

function checkAuthorization(token) {

    const config = {
        headers: {
            'Authorization':  "bearer " + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    const params = new URLSearchParams();

    return Axios.post(apiConstants.API_URL + '/current', params, config)
        .then((response) => {
            if (response.status === 401) {
                localStorage.removeItem('token');
                window.location.reload();
            }

            return response;
        })
        .catch(error => {
            localStorage.removeItem('token');
            window.location.reload();
        });

}

function selfUpdate(params) {

    const config = {
        headers: {
            'Authorization':  "bearer " + this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return Axios.put(apiConstants.API_URL + '/users', params, config)
        .then((response) => {
            return response;
        })
}

function remove(id) {
    const config = {
        headers: {
            'Authorization':  "bearer " + this.token
        }
    };

    return Axios.delete(apiConstants.API_URL + '/users/' + id, config)
        .then((response) => {
            return response;
        })
}

function add(params) {
    const config = {
        headers: {
            'Authorization':  "bearer " + this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return Axios.post(apiConstants.API_URL + '/users', params, config)
        .then((response) => {
            return response;
        })
}

function get(id, handler) {
    const config = {
        headers: {
            'Authorization': 'bearer ' + this.token
        }
    };

    return Axios.get(apiConstants.API_URL + '/users/' + id, config)
        .then(response => {
            if (response.status === 200) {
                const result = response.data;
                if (result.status === 'success') {
                    const data = result.data;
                    handler(data)
                }
            }
        })
}

function update(id, params) {
    const config = {
        headers: {
            'Authorization': 'bearer ' + this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return Axios.put(apiConstants.API_URL + '/users/' + id, params, config)
        .then((response) => {
            return response;
        })
}
