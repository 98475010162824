import React from "react"
import {headerSortingClasses, sortCaret} from "../../widgets/TableSortingHelpers";
import dateFormat from "dateformat";
import {SignMosOfferContainer} from "../../components/containers/SignButtonContainers";
import FilteredTable from "../../components/containers/FilteredTable";
import {apiConstants} from "../../constants/ApiConstants";

export function MosOffers() {

  let filterView = (state, activeState, handler) => {
    const statusClass = (state.State === 1 ? 'badge-success' : 'badge-warning');
    return (
      <button type="button" className={"btn btn-light"+(activeState === state.State ? ' active' : '')} onClick={() => handler(state.State)}>
        {state.State === 1 ? 'Подписаны' : 'Не подписаны'} <span className={`badge ${statusClass}`}>{state.Count}</span>
      </button>
    );
  }

  const columns = [
    {
      dataField: "SettingsID",
      text: "Аккаунт",
      headerClasses: "text-nowrap",
      classes: "text-nowrap",
    },
    {
      dataField: "CreateDate",
      text: "Дата",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      formatter: function (cell, row) {
        return dateFormat(row.CreateDate, "HH:MM dd.mm.yy");
      },
    },
    {
      dataField: "RegNum",
      text: "ID",
      sort: false,
    },
    {
      dataField: "Name",
      text: "Название",
      sort: false,
      formatter: function (cell, row) {
        return (<a href={"https://old.zakupki.mos.ru/#/offers/my/"+row.RegNum} target="_blank">{row.Name}</a>);
      },
    },
    {
      dataField: "Name",
      text: "Закупка",
      sort: false,
      formatter: function (cell, row) {
        if (row.Auction) {
          const regex = /([\d]+)/gm;
          const id = regex.exec(row.Auction);
          return (<a href={row.Auction} target="_blank">{id[0]}</a>);
        } else {
          return '';
        }
      },
    },
    {
      dataField: "Amount",
      text: "Сумма",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "Status",
      text: "Статус",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      headerClasses: "text-nowrap",
      formatter: function (cell, row) {
        return (
          row.Status === 1 ? <span className="state-4 badge badge-success">Подписана</span> : <span className="state-4 badge badge-warning">Не подписана</span>
        );
      },
    },
    {
      dataField: "EndTime",
      text: "Подписать до",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      headerClasses: "text-nowrap",
      formatter: function (cell, row) {
        let dateEndText = '';
        if (row.Status !== 1) {
          if (row.Auction) {
            dateEndText = dateFormat(row.EndTime, "HH:MM dd.mm.yy")
          } else {
            dateEndText = 'Не ограничено';
          }
        } else {
          dateEndText = 'Завершено';
        }
        return dateEndText;
      },
    },
    {
      dataField: "action",
      text: "Действия",
      formatter: function (cell, row) {
        return (row.Status !== 1 ? <SignMosOfferContainer id={row.ID} /> : '')
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    }
  ];

  return (
    <FilteredTable
      sortField={"RegNum"}
      dataUrl={apiConstants.API_URL + '/offer/list'}
      filterView={filterView}
      columns={columns}
      title={"Оферты Mos.ru"}
    />
  );
}