import React from "react"
import Select from 'react-select'

export function UserFormView(props) {
  const mosAccounts = [];
  const mosregAccounts = [];
  const eatAccounts = [];

  if (props.data.mosAccounts) {
    Object.keys(props.data.mosAccounts).map((value) => {
      if (props.data.mosAccounts[value]) {
        mosAccounts.push({value: value, label: props.data.mosAccounts[value]})
      }
    })
  }

  if (props.data.mosregAccounts) {
    Object.keys(props.data.mosregAccounts).map((value) => {
      if (props.data.mosregAccounts[value]) {
        mosregAccounts.push({value: value, label: props.data.mosregAccounts[value]})
      }
    })
  }

  if (props.data.eatAccounts) {
    Object.keys(props.data.eatAccounts).map((value) => {
      if (props.data.eatAccounts[value]) {
        eatAccounts.push({value: value, label: props.data.eatAccounts[value]})
      }
    })
  }

  return (
    <form name="form" onSubmit={props.onSubmit}>
      {props.result.type === 'error' && <div className="alert alert-danger">{props.result.message}</div>}
      {props.result.type === 'success' && <div className="alert alert-success">{props.result.message}</div>}
      <div className="form-group col-12">
        <label htmlFor="login">Логин</label>
        <input name="login" type="text" className="form-control" required id="name" placeholder="Логин"
               onChange={props.onChange} value={props.data.login}/>
        <small id="loginHelp" className="form-text text-muted">Введите логин пользователя</small>
      </div>
      <div className="form-group col-12">
        <label htmlFor="link">E-mail</label>
        <input name="email" type="email" className="form-control" id="email" placeholder="Email"
               onChange={props.onChange} value={props.data.email}/>
        <small id="emailHelp" className="form-text text-muted">Ссылка на страницу котировочной сессии на портале
          zakupki.mos.ru</small>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="password">Пароль</label>
            <input name="password" type="password" className="form-control" id="password" placeholder="Пароль"
                   onChange={props.onChange} value={props.data.password}/>
            <small id="passwordHelp" className="form-text text-muted">Задай пароль пользователя</small>
          </div>
          <div className="form-group col-6">
            <label htmlFor="repassword">Повторите пароль</label>
            <input name="repassword" type="password" className="form-control" id="repassword"
                   placeholder="Повторите пароль" onChange={props.onChange} value={props.data.repassword}/>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="phone">Права</label>
            <select value={props.data.role} name="role" className="form-control" onChange={props.onChange}>
              <option value="Admin">Admin</option>
              <option value="User">User</option>
            </select>
            <small id="phoneHelp" className="form-text text-muted">Выбирите права пользователя</small>
          </div>
        </div>

        {
          props.data.role === "User" &&
            <div className="row">
              <div className="form-group col-12">
                <label htmlFor="mosAccounts">Аккаунты Портала поставщиков</label>
                <Select value={props.data.selectedMosAccounts} isMulti={true} placeholder={"Выберите аккаунт"}
                        options={mosAccounts} onChange={props.onMosHandler}/>
                <small id="phoneHelp" className="form-text text-muted">Выбирите к каким аккаунтам пользователь имеет
                  доступ</small>
              </div>
              <div className="form-group col-12">
                <label htmlFor="phone">Аккаунты mosreg.ru</label>
                <Select value={props.data.selectedMosregAccounts} isMulti={true} placeholder={"Выберите аккаунт"}
                        options={mosregAccounts} onChange={props.onMosregHandler}/>
                <small id="phoneHelp" className="form-text text-muted">Выбирите к каким аккаунтам пользователь имеет
                  доступ</small>
              </div>
              <div className="form-group col-12">
                <label htmlFor="mosAccounts">Аккаунты Березки</label>
                <Select value={props.data.selectedEatAccounts} isMulti={true} placeholder={"Выберите аккаунт"}
                        options={eatAccounts} onChange={props.onEatHandler}/>
                <small id="phoneHelp" className="form-text text-muted">Выбирите к каким аккаунтам пользователь имеет
                  доступ</small>
              </div>
            </div>
        }
        <button type="submit" className="btn btn-primary" disabled={props.result.type === 'loading'}>
          {props.result.type === 'loading' &&
          <span className="spinner-border spinner-border-sm" role="status"></span>} Сохранить
        </button>
      </div>
    </form>
  );
}