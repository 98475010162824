import React from "react"
import {ContentLayout} from "../layouts/ContentLayout";
import EditProfileFormContainer from "../components/containers/EditProfileFormContainer";

export function Profile() {
    return (
        <ContentLayout title="Профиль">
            <div className="row">
                <div className="col-6">
                    <br />
                    <EditProfileFormContainer />
                </div>
            </div>
        </ContentLayout>
    );
}