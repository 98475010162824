import React from "react";

export function UserListTable(props) {
    return (
        <div className="table-responsive-lg">
            <table className="table table-sm table-light table-striped table-row-border table-condensed">
                <thead>
                <tr>
                    <th className="text-nowrap">Login</th>
                    <th className="text-nowrap">Email</th>
                    <th className="text-nowrap">Права</th>
                    <th className="text-nowrap">#</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.items !== null && props.items.length > 0 ?
                    props.items.map((item) => (
                        <UserListTableItem key={item.ID} item={item} editHandler={props.editHandler} deleteHandler={props.deleteHandler} />
                    )) :
                        <tr>
                            <td colSpan="4">Пользователей не найдено</td>
                        </tr>
                }
                </tbody>
            </table>
        </div>
    );
}

function UserListTableItem(props) {

    const item = props.item;

    let email = '';
    if (item.Email !== undefined) {
        email = item.Email
    }
    return (
        <tr>
            <td className="text-nowrap">{item.Login}</td>
            <td>{email}</td>
            <td>{item.Role}</td>
            <td className="action-bar">
                <div className="btn-group btn-group-sm action-group" role="group">
                    <button onClick={() => {props.editHandler(item.ID)}} type="button" className="btn btn-success edit" title="Редактировать">
                        <i className="fa fa-edit"></i>
                    </button>
                    <button onClick={() => {props.deleteHandler(item.ID)}} type="button" className="btn btn-danger delete" title="Удалить">
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            </td>
        </tr>
    );
}