import React from 'react';
import {Router, Route, Switch} from "react-router-dom";

import MainLayout from "../layouts/MainLayout";

import SignIn from "../pages/SignIn";
import {MosregTasks} from "../pages/mosreg.ru/MosregTasks";
import {MosOffers} from "../pages/mos.ru/MosOffers";
import {MosContracts} from "../pages/mos.ru/MosContracts";
import {MosWaitList} from "../pages/mos.ru/MosWaitList";
import {MosregContracts} from "../pages/mosreg.ru/MosregContracts";
import {MosregSettingsForm} from "../pages/mosreg.ru/MosregSettingsForm";
import MosregSettings from "../pages/mosreg.ru/MosregSettings";
import {MosregTaskForm} from "../pages/mosreg.ru/MosregTaskForm";
import {HomePage} from "../pages/Home";
import {Profile} from "../pages/Profile";
import {Users} from "../pages/admin/Users";
import {UserForm} from "../pages/admin/UserForm";
import {Keywords} from "../pages/parser/Keywords";
import {ParseResult} from "../pages/parser/ParseResult";
import {NotFoundPage} from "../pages/NotFoundPage";
import {history} from "./History";
import {Store} from "./Store";
import {userActions} from "../actions/UserActions";
import {toast} from "react-toastify";
import {routeConstants} from "../constants/RouteConstants";
import {AddKeyword} from "../pages/parser/AddKeyword";
import {Settings} from "../pages/Settings";
import {B24} from "../pages/B24";
import {Stopwords} from "../pages/parser/Stopwords";
import {AddStopword} from "../pages/parser/AddStopword";
import {EatTasks} from "../pages/eat/EatTasks";
import EatSettings from "../pages/eat/EatSettings";
import {EatTaskForm} from "../pages/eat/EatTaskForm";
import {EatContracts} from "../pages/eat/EatContracts";
import {MosregWaitList} from "../pages/mosreg.ru/MosregWaitList";
import {MosTasks} from "../pages/mos.ru/MosTasks";
import {MosExecutions} from "../pages/mos.ru/MosExecutions";
import MosSettings from "../pages/mos.ru/MosSettings";
import {MosSettingsForm} from "../pages/mos.ru/MosSettingsForm";
import {MosTaskForm} from "../pages/mos.ru/MosTaskForm";
import {EatSettingsForm} from "../pages/eat/EatSettingsForm";
import {Forbidden} from "../pages/Forbidden";
import SearchWidget from "../pages/search/SearchWidget";
import MosCompetitors from "../pages/mos.ru/MosCompetitors";
import MosregCompetitors from "../pages/mosreg.ru/MosregCompetitors";
import MosDocuments from "../pages/mos.ru/MosDocuments";

function AppRouter() {

    toast.configure({
        autoClose: 3000,
        draggable: false,
    });

    const token = localStorage.getItem('token');

    if (token !== null && token.length > 0) {

        Store.dispatch(userActions.authorized(token));

        return (
            <Router history={history}>
                <MainLayout>
                    <Switch>
                        <Route exact path={routeConstants.MAIN_PAGE}>
                            <HomePage/>
                        </Route>
                        <Route path={routeConstants.MOS_COMPETITORS}>
                            <MosCompetitors/>
                        </Route>
                        <Route path={routeConstants.MOS_DOCUMENTS}>
                            <MosDocuments />
                        </Route>
                        <Route path={routeConstants.MOS_OFFERS}>
                            <MosOffers/>
                        </Route>
                        <Route path={routeConstants.MOS_CONTRACTS}>
                            <MosContracts/>
                        </Route>
                        <Route path={routeConstants.MOS_WAIT_LIST}>
                            <MosWaitList/>
                        </Route>
                        <Route path={routeConstants.MOS_EXECUTIONS}>
                            <MosExecutions />
                        </Route>

                        {/** Настройки Mos.ru */}
                        <Route exact={true} path={routeConstants.MOS_SETTINGS_ADD}>
                            <MosSettingsForm />
                        </Route>
                        <Route path={routeConstants.MOS_SETTINGS + "/:id"}>
                            <MosSettingsForm />
                        </Route>
                        <Route exact={true} path={routeConstants.MOS_SETTINGS}>
                            <MosSettings/>
                        </Route>

                        {/** Задачи Mos.ru */}
                        <Route path={routeConstants.MOSREG_COMPETITORS}>
                            <MosregCompetitors/>
                        </Route>
                        <Route path={routeConstants.MOS_TASK_ADD}>
                            <MosTaskForm />
                        </Route>
                        <Route path={routeConstants.MOS_TASKS + "/:id"}>
                            <MosTaskForm />
                        </Route>
                        <Route path={routeConstants.MOS_TASKS}>
                            <MosTasks/>
                        </Route>

                        {/** Настройки Mosreg.ru */}
                        <Route exact={true} path={routeConstants.MOSREG_SETTINGS_ADD}>
                            <MosregSettingsForm />
                        </Route>
                        <Route path={routeConstants.MOSREG_SETTINGS + "/:id"}>
                            <MosregSettingsForm />
                        </Route>
                        <Route path={routeConstants.MOSREG_SETTINGS}>
                            <MosregSettings />
                        </Route>


                        <Route path={routeConstants.MOSREG_CONTRACTS}>
                            <MosregContracts/>
                        </Route>
                        <Route path={routeConstants.MOSREG_WAIT_LIST}>
                            <MosregWaitList/>
                        </Route>

                        {/** Задачи для Mosreg.ru */}
                        <Route path={routeConstants.MOSREG_TASKS_ADD}>
                            <MosregTaskForm />
                        </Route>
                        <Route path={routeConstants.MOSREG_TASKS + "/:id"}>
                            <MosregTaskForm />
                        </Route>
                        <Route path={routeConstants.MOSREG_TASKS}>
                            <MosregTasks />
                        </Route>

                        {/** Настройки Agregatoreat.ru */}
                        <Route exact={true} path={routeConstants.EAT_SETTINGS_ADD}>
                            <EatSettingsForm />
                        </Route>
                        <Route path={routeConstants.EAT_SETTINGS + "/:id"}>
                            <EatSettingsForm />
                        </Route>
                        <Route path={routeConstants.EAT_SETTINGS}>
                            <EatSettings />
                        </Route>

                        <Route path={routeConstants.EAT_CONTRACTS}>
                            <EatContracts/>
                        </Route>

                        {/** Задачи Agregatoreat.ru */}
                        <Route exact={true} path={routeConstants.EAT_TASK_ADD}>
                            <EatTaskForm />
                        </Route>
                        <Route path={routeConstants.EAT_TASKS + "/:id"}>
                            <EatTaskForm />
                        </Route>
                        <Route path={routeConstants.EAT_TASKS}>
                            <EatTasks />
                        </Route>

                        <Route path={routeConstants.USER_PROFILE}>
                            <Profile/>
                        </Route>
                        <Route path={routeConstants.USER_ADD}>
                            <UserForm />
                        </Route>
                        <Route path={routeConstants.USERS + "/:id"}>
                            <UserForm />
                        </Route>
                        <Route path={routeConstants.USERS}>
                            <Users />
                        </Route>
                        <Route path={routeConstants.PARSER_KEYWORD_ADD}>
                            <AddKeyword/>
                        </Route>
                        <Route path={routeConstants.PARSER_KEYWORDS + "/:id"}>
                            <AddKeyword/>
                        </Route>
                        <Route path={routeConstants.PARSER_KEYWORDS}>
                            <Keywords/>
                        </Route>
                        <Route path={routeConstants.PARSER_STOPWORD_ADD}>
                            <AddStopword/>
                        </Route>
                        <Route path={routeConstants.PARSER_STOPWORDS}>
                            <Stopwords/>
                        </Route>
                        <Route path={routeConstants.PARSER_RESULT}>
                            <ParseResult/>
                        </Route>
                        <Route path={routeConstants.APP_SETTINGS}>
                            <Settings/>
                        </Route>
                        <Route path={routeConstants.B24_INTEGRATION}>
                            <B24 />
                        </Route>
                        <Route path={[routeConstants.SEARCH + "/:id/:action/:platform", routeConstants.SEARCH + "/:id", routeConstants.SEARCH]} component={SearchWidget} />
                        <Route path="/error">
                            <Forbidden />
                        </Route>
                        <Route path="*">
                            <NotFoundPage/>
                        </Route>
                    </Switch>
                </MainLayout>
            </Router>
        );
    } else {
        return (
            <SignIn/>
        );
    }
}

export default AppRouter;