import React from "react"
import {apiConstants} from "../../constants/ApiConstants";
import {headerSortingClasses, sortCaret} from "../../widgets/TableSortingHelpers";
import dateFormat from "dateformat";
import FilteredTable from "../../components/containers/FilteredTable";

export function EatContracts() {

  let filterView = (state, activeState, handler) => {
    const statusClass = classByStatus(state.State);

    return (
      <button type="button" className={"btn btn-light"+(activeState === state.State ? ' active' : '')} onClick={() => handler(state.State)}>
        {state.State} <span className={`badge ${statusClass}`}>{state.Count}</span>
      </button>
    );
  }

  const columns = [
    {
      dataField: "CreateDate",
      text: "Дата",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      formatter: function (cell, row) {
        return dateFormat(row.CreateDate, "HH:MM dd.mm.yy");
      },
    },
    {
      dataField: "RegNum",
      text: "ID",
      sort: false,
      classes: "text-nowrap",
    },
    {
      dataField: "Name",
      text: "Название",
      sort: false,
      formatter: function (cell, row) {
        return (<a href={row.Url} target="_blank">{row.Name}</a>);
      },
    },
    {
      dataField: "Name",
      text: "Закупка",
      sort: false,
      formatter: function (cell, row) {
        if (row.Auction) {
          const regex = /([\d]+)/gm;
          const id = regex.exec(row.Auction);
          return (<a href={row.Auction} target="_blank">{id}</a>);
        } else {
          return '';
        }
      },
    },
    {
      dataField: "Amount",
      text: "Сумма",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "Status",
      text: "Статус",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      headerClasses: "text-nowrap",
      formatter: function (cell, row) {
        const statusClass = classByStatus(row.Status);

        return (
          <span className={"state-4 badge " + statusClass}>
              {row.Status}
          </span>
        );
      },
    },
    {
      dataField: "action",
      text: "Действия",
      formatter: function (cell, row) {
        //return (row.Status === "Направлен поставщику" ? <SignMosregContractContainer id={row.ID} /> : '')
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    }
  ];

  return (
    <FilteredTable
      sortField={"RegNum"}
      dataUrl={apiConstants.API_URL + '/contract_eat/list'}
      filterView={filterView}
      columns={columns}
      title={"Контракты Березка"}
    />
  );
}

function classByStatus(name) {
  let statusClass = 'badge-primary';

  // eslint-disable-next-line default-case
  switch (name) {
    case "Документы подписаны поставщиком и заказчиком":
      statusClass = "badge-success";
      break;
    default:
      statusClass = "badge-warning";
  }

  return statusClass;
}