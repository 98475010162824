let port = '';



if (process.env.NODE_ENV === 'development') {
    port = ':8888'
}

export const apiConstants = {
    API_URL: window.location.protocol + '//' + window.location.hostname + port + '/api',
    //SEARCH_URL:  process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : 'https://search.tender-help.ru/api'
    SEARCH_URL:  'https://search.tender-help.ru/api'
};
