import React from "react";
import Axios from "axios";
import {Loading} from "../../widgets/Loading";
import {Pagination} from "../../widgets/Pagination";
import {apiConstants} from "../../constants/ApiConstants";
import {UserListTable} from "../views/UserList";
import {history} from "../../app/History";
import {userService} from "../../services/UserServices";
import {routeConstants} from "../../constants/RouteConstants";

export class UserListContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            items: [],
            page: 1,
            pagination: []
        };

        this.handleDelete = this.handleDelete.bind(this);
    }

    handlePageClick = (page) => {
        this.setState({
            page: page
        });
        this.loadData(page);
    };

    loadData = (page = 1) => {
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'Authorization': 'bearer ' + token
            }
        };

        Axios.get(apiConstants.API_URL + "/users?page=" + page, config ).then((response) => {
            const result = response.data;
            const items = result.data.Items;
            const pagination = result.data.Pagination;
            this.setState(state => ({items: items, pagination: pagination, isLoading: false}));
        }).catch(err => {
            console.log(err);
        });
    };

    componentDidMount() {
        this.loadData();
    }

    handleEdit(id) {
        history.push(routeConstants.USERS + '/' + id);
    }

    handleDelete(id) {
        userService.remove(id).then(response => {
            if (response.status === 200) {
                this.loadData(this.state.page);
            }
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        } else {
            return (
                <React.Fragment>
                    <UserListTable items={this.state.items} editHandler={this.handleEdit} deleteHandler={this.handleDelete} />
                    <br />
                    {this.state.pagination.Pages !== undefined && <Pagination pagination={this.state.pagination} handle={this.handlePageClick} />}
                </React.Fragment>
            );
        }
    }
}