import React from "react";
import {headerSortingClasses, sortCaret} from "../../widgets/TableSortingHelpers";
import dateFormat from "dateformat";
import FilteredTable from "../../components/containers/FilteredTable";
import {apiConstants} from "../../constants/ApiConstants";
import {routeConstants} from "../../constants/RouteConstants";
import {classByStatus} from "./MosContracts";
import {SignMosContractContainer, SignMosOfferContainer} from "../../components/containers/SignButtonContainers";

export default function MosDocuments() {
    let filterView = (state, activeState, handler) => {
        //return (<StateFilterOption state={state} active={activeState === state.State} onClick={handler} />)
    }

    const columns = [
        {
            dataField: "SettingsID",
            text: "Аккаунт",
            headerClasses: "text-nowrap",
        },
        {
            dataField: "EndTime",
            text: "Окончание",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            headerClasses: "text-nowrap",
            formatter: function (cell, row) {
                return dateFormat(row.EndTime, "HH:MM dd.mm.yy");
            },
        },
        {
            dataField: "Name",
            text: "Название",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: function (cell, row) {
                return (<a href={row.Link} target="_blank">{row.Name}</a>);
            },
        },
        {
            dataField: "LastPrice",
            text: "Цена",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            headerClasses: "text-nowrap",
        },
        {
            dataField: "User",
            text: "Пользователь",
            sort: false
        },
        {
            dataField: "documents",
            text: "Оферты",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            headerClasses: "text-nowrap",
            formatter: function (cell, row) {
                return (
                    row?.documents?.offers?.items?.length ?
                        row.documents.offers?.signed ?
                            <span className="state-1 badge badge-success">Подписаны</span> :
                                <span className="state-1 badge badge-warning">Не подписаны</span> :
                                    <span className="state-1 badge badge-danger">Не созданы</span>

                );
            },
        },
        {
            dataField: "documents",
            text: "Контракт",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            headerClasses: "text-nowrap",
            formatter: function (cell, row) {
                return (
                    row?.documents?.contract?.id > 0 ?
                      <span className={"state-1 badge " + classByStatus(row.documents.contract.state_name)}>{row.documents.contract.state_name}</span> :
                        <span className="state-1 badge badge-secondary">Не выгружен</span>

                );
            },
        },
        {
            dataField: "documents",
            text: "Подписать до",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            headerClasses: "text-nowrap",
            formatter: function (cell, row) {
                if (row?.documents?.contract?.id > 0) {
                    const time = row.documents.contract.required_date;
                    return time !== '0001-01-01T00:00:00Z' ? dateFormat(time, "HH:MM dd.mm.yy") : '';
                } else {
                    const time = row.documents.offers.signed_time;
                    return time !== '0001-01-01T00:00:00Z' && !row.documents.offers?.signed ? dateFormat(time, "HH:MM dd.mm.yy") : '';
                }
            },
        },
        {
            dataField: "action",
            text: "",
            formatter: function (cell, row) {
                let signButton = null;
                if (row?.documents?.offers?.items?.length) {
                    if (!row.documents.offers?.signed) {
                        signButton = <SignMosOfferContainer id={row.id} />;
                    } else if (row.documents.contract.state_name === "Направлен поставщику") {
                        signButton = <SignMosContractContainer id={row.id} />;
                    }
                } else {
                    signButton = (
                        <button onClick={this.handleEdit} type="button" className="btn btn-warning edit" title="Создать оферты">
                            <i className="fa fa-arrow-circle-o-down"></i>
                        </button>
                    )
                }

                return (
                    <div className="btn-group btn-group-sm action-group" role="group">
                        {signButton}
                        <button type="button" className="btn btn-success edit" title="Принудительно обновить">
                            <i className="fa fa-refresh"></i>
                        </button>
                        <button type="button" className="btn btn-danger delete" title="Удалить все">
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                )
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        }
    ];

    return (
        <>
            <FilteredTable
                sortField={"EndTime"}
                dataUrl={apiConstants.API_URL + '/tasks'}
                filterView={filterView}
                columns={columns}
                title={"Оферты и контракты на Mos.ru"}
                state={'4'}
            />
        </>
    );
}