import React from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import StopwordAddFormContainer from "../../components/containers/StopwordAddFormContainer";

export function AddStopword() {
    return (
        <ContentLayout title="Добавление стоп слова">
            <div className="row">
                <div className="col-6 p-0">
                    <br />
                    <StopwordAddFormContainer />
                </div>
            </div>
        </ContentLayout>
    );
}