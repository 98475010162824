export const routeConstants = {
    MAIN_PAGE: '/',
    MOS_TASKS: '/mos/tasks',
    MOS_TASK_ADD: '/mos/tasks/add',
    MOS_COMPETITORS: '/mos/competitors',
    MOS_DOCUMENTS: '/mos/documents',
    MOS_OFFERS: '/mos/offers',
    MOS_CONTRACTS: '/mos/contracts',
    MOS_EXECUTIONS: '/mos/executions',
    MOS_WAIT_LIST: '/mos/waitlist',
    MOS_SETTINGS: '/mos/settings',
    MOS_SETTINGS_ADD: '/mos/settings/add',
    MOSREG_TASKS: '/mo/tasks',
    MOSREG_TASKS_ADD: '/mo/tasks/add',
    MOSREG_COMPETITORS: '/mo/competitors',
    MOSREG_CONTRACTS: '/mo/contracts',
    MOSREG_WAIT_LIST: '/mo/waitlist',
    MOSREG_SETTINGS: '/mo/settings',
    MOSREG_SETTINGS_ADD: '/mo/settings/add',
    EAT_TASKS: '/eat/tasks',
    EAT_TASK_ADD: '/eat/tasks/add',
    EAT_CONTRACTS: '/eat/contracts',
    EAT_SETTINGS: '/eat/settings',
    EAT_SETTINGS_ADD: '/eat/settings/add',
    USER_PROFILE: '/profile',
    USERS: '/users',
    USER_ADD: '/users/add',
    APP_SETTINGS: '/settings',
    B24_INTEGRATION: '/b24',
    PARSER_RESULT: '/parser',
    PARSER_KEYWORDS: '/parser/keywords',
    PARSER_KEYWORD_ADD: '/parser/keywords/add',
    PARSER_STOPWORDS: '/parser/stopwords',
    PARSER_STOPWORD_ADD: '/parser/stopwords/add',
    SEARCH: '/search'
};
