import React from "react";
import {useHistory, useParams} from "react-router";
import MosTaskFormContainer from "../../components/containers/MosTaskFormContainer";
import MosregTaskFormContainer from "../../components/containers/MosregTaskFormContainer";
import EatTaskFormContainer from "../../components/containers/EatTaskFormContainer";

export default function NewTask() {

  const history = useHistory();

  return (
    <>
      <div className="bs-canvas-backdrop show"/>
      <div className="bs-canvas bs-canvas-anim bs-canvas-right position-fixed bg-white h-100">
        <header className="bs-canvas-header bg-secondary pt-3 pb-3 pr-3 pl-5 overflow-auto position-relative">
          <button
            type="button"
            className="bs-canvas-close float-left close position-absolute"
            style={{left: "20px"}}
            onClick={() => {
              document.body.style.overflowY = 'scroll';
              history.push('/search');
            }}
          >
            <span aria-hidden="true" className="text-light">&times;</span>
          </button>
          <h4 className="d-inline-block text-light mb-0 float-left tender-title">
            <span className={'text-light'}>Задача для робота</span>
          </h4>
        </header>
        <div className="bs-canvas-content px-2 py-3">
          <TaskForm />
        </div>
      </div>
    </>
  )
}

function TaskForm() {
  const {id, platform} = useParams();

  if (platform === 'mosru')
    return (<MosTaskFormContainer external_id={id} />)
  else if (platform === 'mosreg')
    return (<MosregTaskFormContainer external_id={id}/>)
  else if (platform === 'eat')
    return (<EatTaskFormContainer external_id={id}/>)
  else {
    return <></>
  }
}