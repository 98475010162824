import React from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import MosregSettingsFormContainer from "../../components/containers/MosregSettingsFormContainer";
import {useSelector} from "react-redux";
import {acccessConstants} from "../../constants/AccessConstants";
import {Forbidden} from "../Forbidden";
import {useParams} from "react-router";

export let MosregSettingsForm = () => {

  const user = useSelector(state => state.userReducer)

  let {id} = useParams();

  return (
    <ContentLayout title={id ? "Редактирование аккаунта Mosreg.ru" : "Добавить аккаунт Mosreg.ru"}>
      <div className="row">
        <br/>
        {
          user.role.toUpperCase() === acccessConstants.ADMIN ?
            <div className="col-6">
              <br/>
              <MosregSettingsFormContainer id={id}/>
            </div> :
            <div className="col-12">
              <Forbidden/>
            </div>
        }
      </div>
    </ContentLayout>
  );
};
