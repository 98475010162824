import React from "react"
import {Loading} from "../../widgets/Loading";
import {connect} from "react-redux";
import {formActions} from "../../actions/FormActions";
import {userActions} from "../../actions/UserActions";
import {EditProfileForm} from "../views/EditProfileForm";

class EditProfileFormContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            login: props.user.login,
            password: '',
            repassword: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(formActions.reset());
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        dispatch(formActions.loading());

        if (this.state.password !== this.state.repassword) {
            dispatch(formActions.error('Пароли не совпадают'));
        } else if (this.state.password.length > 0) {
            const params = new URLSearchParams();
            params.append('password', this.state.password);
            dispatch(userActions.selfUpdate(params));
            this.setState({
                password: '',
                repassword: ''
            })
        } else {
            dispatch(formActions.error('Укажите новый пароль'));
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        } else {
            return <EditProfileForm data={this.state} result={this.props.result} onChange={this.handleChange} onSubmit={this.handleSubmit}/>
        }
    }
}

function mapStateToProps(store) {
    return {
        user: store.userReducer,
        result: store.formReducer
    };
}

export default connect(mapStateToProps)(EditProfileFormContainer);